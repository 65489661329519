<template>
  <section id="events-list" class="route-content">
    <loader v-if="loading" />

    <header class="flex" v-if="eventRef">
      <image-loader
        :image-url="eventRef.eventImage"
        alt="Event thumbnail"
        width="auto"
        height="80"
      />
      <hr class="divider divider--vertical" />
      <event-subheader :event="eventRef" />
    </header>

    <branded-divider />

    <template v-if="participants.length">
      <h4>Participants</h4>
      <zoom-user-list-item
        v-for="(z, i) in participants"
        :key="`${i}-${z.id}`"
        :user="z"
      />
    </template>

    <div class="list-item error--text sticky">
      <b v-if="error">{{ error }}</b>

      <p
        class="list-item--label column"
        v-if="registrants.length && !participants.length"
      >
        <span class="wide">
          None of {{ registrants.length }} registrants were found in the meeting
          records.</span
        >
        <button
          class="wide"
          :class="{ outline: showRawAttendees, button: !showRawAttendees }"
          @click="showRawAttendees = !showRawAttendees"
        >
          {{ showRawAttendees ? "Hide" : "Show" }} participants
        </button>
      </p>
    </div>

    <!-- Raw attendees and participants data -->
    <div
      class="raw-attendees"
      :class="{ 'slide-down-fade-in': showRawAttendees }"
      v-if="showRawAttendees"
    >
      <div class="col-50">
        <h5>Registrants</h5>
        <hr class="divider divider--md grey-light" />

        <div
          class="list-item list-item--label"
          v-for="(registration, i) in registrants"
          :key="`${i}-${registration.id}`"
        >
          <span class="col-50">{{ registration.user.fullName }}</span>
          <span class="grow">{{ registration.user.email }}</span>
        </div>
      </div>

      <hr class="divider divider--vertical" />

      <div class="grow">
        <h5>Participants</h5>
        <hr class="divider divider--md grey-light" />

        <p
          class="list-item list-item--label grey--text"
          v-if="!participantsRaw.length"
        >
          <em>No attendees found</em>
        </p>

        <div
          class="list-item list-item--label"
          v-for="(participant, i) in participantsRaw"
          :key="`${i}-${participant.id}`"
        >
          <span class="col-50">{{ participant.name }}</span>
          <span class="grow">{{ participant.email }}</span>
        </div>
      </div>
    </div>

    <!-- 'Complete Event' Action -->
    <button
      v-if="!confirmComplete"
      :class="{
        'success wide': true,
        'slide-up-fade-out': confirmComplete,
        'slide-down-fade-in': !this.confirmComplete
      }"
      @click.prevent="confirmComplete = true"
    >
      Complete Event
    </button>

    <!-- Confirm 'Complete Event' Action -->
    <div v-if="confirmComplete" class="confirm-complete slide-down-fade-in">
      <p class="h6 error--text">
        <i class="fas fa-exclamation-triangle warning--text"></i>
        <strong>This action is final!</strong>
      </p>

      <p>
        Confirm selected attendees as <b>present</b>
        and complete the
        <b>{{ type.toLowerCase() }}</b
        >?
      </p>

      <!-- Confirm 'Complete Event' -->
      <button class="outline" @click.prevent="completeEvent">
        <span class="link"> <i class="fas fa-check-circle" />Yes </span>
      </button>
      <!-- Cancel 'Complete Event' -->
      <button class="outline" @click.prevent="cancelConfirm">
        <span class="error--text">
          <i class="fas fa-times-circle" />No (cancel)
        </span>
      </button>
    </div>
  </section>
</template>

<script>
import { createOrUpdateEvent, getEventById } from "../models/events";
import {
  getClassRegistrations,
  getEventRegistrations
} from "../models/registrations";
import { getMeetingParticipants } from "../models/zoom";
import BrandedDivider from "./BrandedDivider.vue";
import EventSubheader from "./EventSubheader.vue";
import ImageLoader from "./ImageLoader.vue";
import Loader from "./Loader.vue";
import PermissionsMixin from "./mixins/permissions.mixin";
import RegistrationsMixin from "./mixins/registrations.mixin";
import ZoomUserListItem from "./ZoomUserListItem.vue";

export default {
  name: "Attendees",

  components: {
    Loader,
    EventSubheader,
    BrandedDivider,
    ImageLoader,
    ZoomUserListItem
  },

  mixins: [PermissionsMixin, RegistrationsMixin],

  data: () => ({
    confirmComplete: false,
    eventRef: null,
    loading: false,
    showRawAttendees: false,
    absentees: [],
    registrants: [],
    participants: [],
    participantsRaw: []
  }),

  computed: {
    event() {
      return this.eventRef;
    }
  },

  async mounted() {
    const id = this.eventId || this.classId;

    if (id) {
      this.startLoading("Fetching attendees ...");
      this.eventRef = await getEventById(id);
      // Exit if not assigned: else, get meeting participants
      if (!this.assigned && !this.isAdminUser) {
        this.$router.push(this.viewEventRoute);
      } else await this.fetchParticipants();

      this.loading = false;
    }
  },

  methods: {
    cancelConfirm() {
      this.confirmComplete = false;
      this.error = null;
    },

    async fetchParticipants() {
      try {
        this.startLoading("Fetching Participants ... ");
        const names = new Set();
        const emails = new Set();
        const { id, isEvent, zoomMeetingId } = this.eventRef;
        const [participants, registrants] = await Promise.all([
          getMeetingParticipants(zoomMeetingId),
          isEvent
            ? getEventRegistrations({ eventId: id })
            : getClassRegistrations({ classId: id })
        ]);

        participants.forEach(({ fullName, email }) => {
          names.add(fullName);
          emails.add(email);
        });

        const findAttendee = ({ fullName, email }) =>
          names.has(fullName) || emails.has(email);

        this.registrants = registrants;
        this.participantsRaw = participants;
        // Filter actual participants and attendees
        registrants.forEach(registrant => {
          if (findAttendee(registrant.user))
            this.participants.push(registrant.user);
          else this.absentees.push(registrant);
        });
      } catch (error) {
        this.onViewError(error.message || error);
      }

      this.loading = false;
    },

    async completeEvent() {
      this.startLoading("Marking attendance ...");

      try {
        // Update eventRef and return to registrations
        const updates = { ...this.eventRef };
        // Update repeating event date (for reuse)
        updates.eventStatus = "Completed";

        if (this.absentees.length) updates.absentees = this.absentees;
        this.eventRef = await createOrUpdateEvent(updates, this.eventRef);
        this.stopLoading();
        const params = { [this.idKey]: this.data.id };
        this.$router.push({ name: `View${this.eventRef.type}`, params });
      } catch (error) {
        this.onViewError(error.message || error);
        this.loading = false;
      }
    }
  }
};
</script>

.<style lang="scss">
#events-list {
  .description {
    align-items: center;
    display: flex;
    flex-direction: column;
    place-content: center;
    min-height: 25vh;
  }

  .list-item {
    border-color: $grey-light;
    margin-bottom: $xxs;
  }

  .list-item.error--text {
    border-color: $error;
  }

  .raw-attendees {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
  }
}

.confirm-complete {
  text-align: center;

  button.outline {
    border: none;
    box-shadow: none;
  }
}

@media screen and (max-width: 600px) {
  #events-list {
    header {
      flex-direction: column;

      img {
        height: auto;
        width: $parent;
      }

      h4 {
        font-size: $md;
      }
    }
  }
}
</style>
